// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";


import { faAngleRight       as fasFaAngleRight          } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faArrowLeft        as fasFaArrowLeft           } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import { faArrowRight       as fasFaArrowRight          } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faBars             as fasFaBars                } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faBoltLightning    as fasFaBoltLightning       } from "@fortawesome/pro-solid-svg-icons/faBoltLightning";
import { faBooks            as fasFaBooks               } from "@fortawesome/pro-solid-svg-icons/faBooks";
import { faCalendarAlt      as fasFaCalendarAlt         } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import { faCaretDown        as fasFaCaretDown           } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft        as fasFaCaretLeft           } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight       as fasFaCaretRight          } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion   as fasFaCircleQuestion      } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser       as fasFaCircleUser          } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faClock            as fasFaClock               } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment          as fasFaComment             } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFilm             as fasFaFilm                } from "@fortawesome/pro-solid-svg-icons/faFilm";
import { faFolder           as fasFaFolder              } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faHeadphones       as fasFaHeadphones          } from "@fortawesome/pro-solid-svg-icons/faHeadphones";
import { faIdCard           as fasFaIdCard              } from "@fortawesome/pro-solid-svg-icons/faIdCard";
import { faListTree         as fasFaListTree            } from "@fortawesome/pro-solid-svg-icons/faListTree";
import { faMagnifyingGlass  as fasFaMagnifyingGlass     } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faMapMarkerAlt     as fasFaMapMarkerAlt        } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import { faMessages         as fasFaMessages            } from "@fortawesome/pro-solid-svg-icons/faMessages";
import { faMessageMiddle    as fasFaMessageMiddle       } from "@fortawesome/pro-solid-svg-icons/faMessageMiddle";
import { faMessagesQuestion as fasFaMessagesQuestion    } from "@fortawesome/pro-solid-svg-icons/faMessagesQuestion";
import { faMusic            as fasFaMusic               } from "@fortawesome/pro-solid-svg-icons/faMusic";
import { faNewspaper        as fasFaNewspaper           } from "@fortawesome/pro-solid-svg-icons/faNewspaper";
import { faPhotoFilmMusic   as fasFaPhotoFilmMusic      } from "@fortawesome/pro-solid-svg-icons/faPhotoFilmMusic";
import { faPrint            as fasFaPrint               } from "@fortawesome/pro-solid-svg-icons/faPrint";
import { faTablet           as fasFaTablet              } from "@fortawesome/pro-solid-svg-icons/faTablet";
import { faTag              as fasFaTag                 } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faUser             as fasFaUser                } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faXmark            as fasFaXmark               } from "@fortawesome/pro-solid-svg-icons/faXmark";

import { faFacebookF        as fabFaFacebookF           } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter          as fabFaTwitter             } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faInstagram        as fabFaInstagram           } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faGoodreadsG       as fabFaGoodreadsG          } from "@fortawesome/free-brands-svg-icons/faGoodreadsG";
import { faPinterestP       as fabFaPinterestP          } from "@fortawesome/free-brands-svg-icons/faPinterestP";
import { faYoutube          as fabFaYoutube             } from "@fortawesome/free-brands-svg-icons/faYoutube";

/**
 * Add solid icons
 */
library.add(fasFaAngleRight, fasFaArrowLeft, fasFaArrowRight, fasFaBars, fasFaBoltLightning, fasFaBooks, fasFaCalendarAlt, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaComment, fasFaFilm, fasFaFolder, fasFaHeadphones, fasFaIdCard, fasFaListTree, fasFaMagnifyingGlass, fasFaMapMarkerAlt, fasFaMessages, fasFaMessageMiddle, fasFaMessagesQuestion, fasFaMusic, fasFaNewspaper, fasFaPhotoFilmMusic, fasFaPrint, fasFaTablet, fasFaTag, fasFaUser, fasFaXmark);

/**
 * Add solid icons
 */
library.add(fabFaFacebookF, fabFaTwitter, fabFaInstagram, fabFaGoodreadsG, fabFaPinterestP, fabFaYoutube);
/**
 * Watch the DOM to insert icons
 */
dom.watch();
